/* eslint-disable no-unused-vars */
import { configureStore } from '@reduxjs/toolkit'
import foodreducer from './slices/foodSlice'
import moivereducer from './slices/movieSlice'
import authreducer from './slices/authSlice'

export const store = configureStore({
  reducer: {
    auth: authreducer,
    food: foodreducer,
    movie: moivereducer,
  },
})
