/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { clientService } from '../../utils/Service'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import '../../Styles/food.css'
import { Col, Row } from 'react-bootstrap'

const FoodOrderDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { orderItem, created_at, id, total_amount } = location?.state

  const [orderItemData, setOrderItemData] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate])

  const handleBackClick = () => {
    navigate(-1)
  }

  return (
    <div className="container p-2">
      <Row className="d-flex align-items-center">
        <Col className="col-0">
          <img
            alt=""
            src={require('../../Assets/Images/left-white-arrow.png')}
            className="previous-btn"
            onClick={handleBackClick}
            height={30}
            width={30}
          />
        </Col>
        <Col className="col-12">
          <h5 className="text-white text-center">Food Order Details</h5>
        </Col>
      </Row>
      <div>
        <h5 className="text-white fs-6">Order Id : {id}</h5>

        <h5 className="text-white fs-6">
          {' '}
          {moment(created_at).format('ddd, DD MMM | LT')}
        </h5>

        <div className="d-flex justify-content-start flex-wrap gap-2">
          {orderItem.map((item, OrderIndex) => {
            return (
              <div key={OrderIndex}>
                <div className="orderItem-detail-View">
                  <h5 className="text-white fs-6">{item?.product_name}</h5>
                  <h6 className="text-white">
                    Product Price : ${item?.item_price}
                  </h6>
                  <h6 className="text-white">Quantity : {item?.quantity}</h6>
                  <h6 className="text-white">Amount : ${item?.total_amount}</h6>
                </div>
              </div>
            )
          })}
        </div>

        <div className="total-amount-view">
          <p className="text-white total-amount-price">
            {`Amount Paid :`} &nbsp;
          </p>
          <p className="text-white total-amount-price"> ${total_amount}</p>
        </div>
      </div>
    </div>
  )
}

export default FoodOrderDetails
