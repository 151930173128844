/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
//Import library's
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

//Import CSS
import '../Styles/common.css'
import '../Styles/footer.css'
import { clientService } from '../utils/Service'

const Footer = () => {
  const location = useLocation()
  const renderForm = location.pathname === '/'

  const [formData, setFormdata] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })

  const handleSubmit = () => {
    window.location.href =
      'https://fomocinemas.us9.list-manage.com/subscribe?u=bdc255b6bd0f13c2f350b28b5&id=9d95af19c4'
    // e.preventDefault()

    // try {
    //   const response = await clientService.post('', formData)
    // } catch (error) {}
  }

  if (location.search === '?mobile') {
    return ''
  }

  return (
    <footer className="footer" id="footer">
      <div className="container footer-view">
        {renderForm && (
          <div>
            <div className="d-flex gap-3">
              <img
                src={require('../Assets/Images/main-pink-color.png')}
                alt=""
                height={30}
                width={30}
              />
              <div>
                <h4>Join our mailing List</h4>
              </div>
            </div>
            <span>Get information delivered to your inbox weekly.</span>
            <div className="mt-3">
              {/* <div className="">
                <input
                  type="text"
                  placeholder="First Name"
                  className="input"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormdata({ ...formData, firstName: e.target.value })
                  }
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="input"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormdata({ ...formData, lastName: e.target.value })
                  }
                />
              </div>
              <div className="">
                <input
                  type="email"
                  placeholder="Email address"
                  className="input"
                  value={formData.email}
                  onChange={(e) =>
                    setFormdata({ ...formData, email: e.target.value })
                  }
                />
              </div> */}
              <div className="mt-3">
                <button
                  type="submit"
                  onClick={() => {
                    window.location.href =
                      'https://fomocinemas.us9.list-manage.com/subscribe?u=bdc255b6bd0f13c2f350b28b5&id=9d95af19c4'
                  }}
                  className="sub-btn"
                >
                  JOIN
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="d-flex">
          <a
            href="https://www.google.com/maps/place/FoMo+Cinemas/@-37.7717628,144.9763101,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad64393e96851db:0xef396a54e74034f7!8m2!3d-37.7717628!4d144.978885!16s%2Fg%2F11vhmfg92g?entry=ttu"
            target="_blank"
            rel="noreferrer"
            className="google-map-link"
          >
            <div className="d-flex align-items-start">
              <img
                src={require('../Assets/Images/pink-location.png')}
                alt=""
                height={30}
                width={30}
              />
              <div className="mx-4">
                <span className="footer-text">FoMo Cinemas</span>
                <br />
                <span className="footer-text">5 Bluestone Way</span>
                <br />
                <span className="footer-text">East Brunswick</span>
                <div className="mt-3">
                  <span className="footer-text mt-4">
                    ( Located in East Brunswick village,
                  </span>
                  <br />
                  <span className="footer-text">
                    133 Nicholson Street,East Brunswick )
                  </span>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="footer-img">
          <img
            src={require('../Assets/Images/fomo_map1.png')}
            alt=""
            height="100%"
            width="100%"
          />
        </div>
      </div>
    </footer>
  )
}

export default Footer

{
  /* <div className="d-flex flex-row align-item-center footer-icon">
            <img
              src={require('../Assets/Images/email.png')}
              alt=""
              height={30}
              width={30}
              className="white-image"
            />
            <div className="mx-4 text-white">
              <a
                href="mailto:mail@fomocinemas.com.au"
                className="text-white footer-text email"
              >
                mail@fomocinemas.com.au
              </a>
            </div>
          </div> */
}
