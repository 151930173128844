/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import '../Styles/seats.css'

const GenerateSeats = ({ row, seatNumbers, type, seatData, selectSeat }) => {
  const handleSeatSelection = (index) => {
    if (seatData[row][index]?.Status == 0) {
      selectSeat(row, index)
    }
  }

  return (
    <div className="screen-row">
      <div className="row-name">{row}</div>
      <div className={`screen-seats`}>
        {seatNumbers.map((seat, index) => {
          if (seat.type === '') {
            return <div key={index} className="screen-seat"></div>
          }
          return (
            <div
              className="screen-seat"
              onClick={() => handleSeatSelection(seat.index)}
              key={`seat-${index}`}
            >
              {type === 'recliner' ? (
                <img
                  src={
                    seatData[row][seat.index]?.OriginalStatus === 99
                      ? `/icons/selected-r-${seat.type}.png`
                      : seatData[row][seat.index]?.OriginalStatus === 2
                      ? `/icons/available-r-${seat.type}.png`
                      : seatData[row][seat.index]?.Status === 1
                      ? `/icons/available-r-${seat.type}.png`
                      : `/icons/booked-r-${seat.type}.png`
                  }
                  className="recliner-seat"
                  style={
                    seatData[row][seat.index]?.Status === 1
                      ? { pointerEvents: 'none' }
                      : {}
                  }
                />
              ) : (
                <img
                  src={
                    seatData[row][seat.index]?.OriginalStatus === 99
                      ? `/icons/selected-${seat.type}.png`
                      : seatData[row][seat.index]?.OriginalStatus === 2
                      ? `/icons/available-${seat.type}.png`
                      : seatData[row][seat.index]?.Status === 1
                      ? `/icons/available-${seat.type}.png`
                      : `/icons/booked-${seat.type}.png`
                  }
                  className="standard-seat"
                  style={
                    seatData[row][seat.index]?.Status === 1
                      ? { pointerEvents: 'none' }
                      : {}
                  }
                />
              )}
              <div>{seatData[row][seat.index]?.Id} </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SeatMatrix = (props) => {
  const { seatData, ScreenName, selectSeat } = props
  return (
    <div className="movie-complex mt-1">
      <div className="text-center screen-img-view">
        <img src={'/icons/screen-area.svg'} className="screen-img" alt="" />
      </div>
      <div className="screeen-view">
        <div className="row movie-layout">
          <div className="seat-display-view">
            {ScreenName === 'Screen 1' ? (
              <div className="movie-column-1">
                <GenerateSeats
                  row="A"
                  seatNumbers={[
                    { index: -1, type: '' },
                    { index: 11, type: 'left' },
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="B"
                  seatNumbers={[
                    { index: 14, type: 'right' },
                    { index: 13, type: 'left' },
                    { index: 12, type: 'right' },
                    { index: 11, type: 'left' },
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="C"
                  seatNumbers={[
                    { index: 14, type: 'right' },
                    { index: 13, type: 'left' },
                    { index: 12, type: 'right' },
                    { index: 11, type: 'left' },
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="D"
                  seatNumbers={[
                    { index: 12, type: 'right' },
                    { index: 11, type: 'left' },
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="E"
                  seatNumbers={[
                    { index: 12, type: 'right' },
                    { index: 11, type: 'left' },
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="F"
                  seatNumbers={[
                    { index: 11, type: 'left' },
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="G"
                  seatNumbers={[
                    { index: 11, type: 'left' },
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="H"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: 6, type: 'left' },
                    { index: 5, type: 'right' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="J"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
              </div>
            ) : ScreenName === 'Screen 2' ? (
              <div className="movie-column-1">
                <GenerateSeats
                  row="A"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="B"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="C"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="D"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="E"
                  seatNumbers={[
                    { index: -1, type: '' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="F"
                  seatNumbers={[
                    { index: -1, type: '' },
                    { index: -1, type: '' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'right' },
                    { index: -1, type: '' },
                    { index: -1, type: '' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'right' },
                    { index: 0, type: 'left' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
              </div>
            ) : ScreenName === 'Screen 3' ? (
              <div className="movie-column-1">
                <GenerateSeats
                  row="A"
                  seatNumbers={[
                    { index: -1, type: '' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: -1, type: '' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                    { index: -1, type: '' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="B"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="C"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                    // { index: 0, type: 'left', displayIndex: 1 },
                    // { index: 1, type: 'right', displayIndex: 2 },
                    // { index: 2, type: 'left', displayIndex: 3 },
                    // { index: 3, type: 'right', displayIndex: 4 },
                    // { index: -1, type: '' },
                    // { index: 4, type: 'right', displayIndex: 5 },
                    // { index: 7, type: 'left', displayIndex: 6 },
                    // { index: 8, type: 'right', displayIndex: 7 },
                    // { index: 9, type: 'left', displayIndex: 8 },
                    // { index: 10, type: 'right', displayIndex: 9 },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="D"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                    // { index: 0, type: 'left', displayIndex: 1 },
                    // { index: 1, type: 'right', displayIndex: 2 },
                    // { index: 2, type: 'left', displayIndex: 3 },
                    // { index: 3, type: 'right', displayIndex: 4 },
                    // { index: -1, type: '' },
                    // { index: 4, type: 'right', displayIndex: 5 },
                    // { index: 7, type: 'left', displayIndex: 6 },
                    // { index: 8, type: 'right', displayIndex: 7 },
                    // { index: 9, type: 'left', displayIndex: 8 },
                    // { index: 10, type: 'right', displayIndex: 9 },
                  ]}
                  type="reguler"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="E"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 3, type: 'right' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="F"
                  seatNumbers={[
                    { index: 10, type: 'right', displayIndex: 2 },
                    { index: 9, type: 'left', displayIndex: 1 },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
              </div>
            ) : ScreenName === 'Screen 4' ? (
              <div className="movie-column-1">
                <GenerateSeats
                  row="A"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="B"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="C"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="D"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="E"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="F"
                  seatNumbers={[
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: 6, type: 'left' },
                    { index: 5, type: 'right' },
                    { index: 4, type: 'left' },
                    { index: 3, type: 'right' },
                    { index: 2, type: 'left' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
              </div>
            ) : ScreenName === 'Screen 5' ? (
              <div className="movie-column-1">
                <GenerateSeats
                  row="A"
                  seatNumbers={[
                    { index: -1, type: '' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 4, type: 'left' },
                    { index: 3, type: 'right' },
                    { index: 2, type: 'left' },
                    { index: 1, type: 'right' },
                    { index: -1, type: '' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="B"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="C"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="D"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="E"
                  seatNumbers={[
                    { index: 10, type: 'left' },
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: -1, type: '' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="F"
                  seatNumbers={[
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: -1, type: '' },
                    { index: 3, type: 'right' },
                    { index: 2, type: 'right' },
                    { index: 1, type: 'left' },
                    { index: 0, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="G"
                  seatNumbers={[
                    { index: 9, type: 'right', displayIndex: 2 },
                    { index: 8, type: 'left', displayIndex: 1 },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
              </div>
            ) : ScreenName === 'Screen 6' ? (
              <div className="movie-column-1">
                <GenerateSeats
                  row="A"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="B"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="C"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    // { index: 1, type: 'right', displayIndex: 1 },
                    // { index: 2, type: 'left', displayIndex: 2 },
                    // { index: 3, type: 'right', displayIndex: 3 },
                    // { index: 4, type: 'left', displayIndex: 4 },
                    // { index: 5, type: 'right', displayIndex: 5 },
                    // { index: 6, type: 'left', displayIndex: 6 },
                    // { index: 7, type: 'right', displayIndex: 7 },
                    // { index: 8, type: 'left', displayIndex: 8 },
                    // { index: 9, type: 'right', displayIndex: 9 },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="D"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    // { index: 1, type: 'right', displayIndex: 1 },
                    // { index: 2, type: 'left', displayIndex: 2 },
                    // { index: 3, type: 'right', displayIndex: 3 },
                    // { index: 4, type: 'left', displayIndex: 4 },
                    // { index: 5, type: 'right', displayIndex: 5 },
                    // { index: 6, type: 'left', displayIndex: 6 },
                    // { index: 7, type: 'right', displayIndex: 7 },
                    // { index: 8, type: 'left', displayIndex: 8 },
                    // { index: 9, type: 'right', displayIndex: 9 },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="E"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    // { index: 1, type: 'right', displayIndex: 1 },
                    // { index: 2, type: 'left', displayIndex: 2 },
                    // { index: 3, type: 'right', displayIndex: 3 },
                    // { index: 4, type: 'left', displayIndex: 4 },
                    // { index: 5, type: 'right', displayIndex: 5 },
                    // { index: 6, type: 'left', displayIndex: 6 },
                    // { index: 7, type: 'right', displayIndex: 7 },
                    // { index: 8, type: 'left', displayIndex: 8 },
                    // { index: 9, type: 'right', displayIndex: 9 },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="F"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    // { index: 1, type: 'right', displayIndex: 1 },
                    // { index: 2, type: 'left', displayIndex: 2 },
                    // { index: 3, type: 'right', displayIndex: 3 },
                    // { index: 4, type: 'left', displayIndex: 4 },
                    // { index: 5, type: 'right', displayIndex: 5 },
                    // { index: 6, type: 'left', displayIndex: 6 },
                    // { index: 7, type: 'right', displayIndex: 7 },
                    // { index: 8, type: 'left', displayIndex: 8 },
                    // { index: 9, type: 'right', displayIndex: 9 },
                  ]}
                  type="regular"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="G"
                  seatNumbers={[
                    { index: 10, type: 'right' },
                    { index: 9, type: 'left' },
                    { index: 8, type: 'right' },
                    { index: 7, type: 'left' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                    // { index: 1, type: 'right', displayIndex: 1 },
                    // { index: 2, type: 'left', displayIndex: 2 },
                    // { index: 3, type: 'right', displayIndex: 3 },
                    // { index: 4, type: 'left', displayIndex: 4 },
                    // { index: 5, type: 'right', displayIndex: 5 },
                    // { index: 6, type: 'left', displayIndex: 6 },
                    // { index: 7, type: 'right', displayIndex: 7 },
                    // { index: 8, type: 'left', displayIndex: 8 },
                    // { index: 9, type: 'right', displayIndex: 9 },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
                <GenerateSeats
                  row="H"
                  seatNumbers={[
                    { index: 9, type: 'right' },
                    { index: 8, type: 'left' },
                    { index: 7, type: 'right' },
                    { index: 6, type: 'right' },
                    { index: 5, type: 'left' },
                    { index: 4, type: 'right' },
                    { index: 3, type: 'left' },
                    { index: 2, type: 'right' },
                  ]}
                  type="recliner"
                  seatData={seatData}
                  selectSeat={selectSeat}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeatMatrix
