import React from 'react'
import '../Styles/loader.css'
import { Col, Row } from 'react-bootstrap'

const Loader = () => {
  return (
    <div className="loader-container">
      <Col>
        <Row>
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </Row>
      </Col>
    </div>
  )
}

export default Loader
