/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QRCode from 'qrcode.react'
import { clientService } from '../utils/Service'
import { Card, Col, Row } from 'react-bootstrap'
import moment from 'moment'

const OrderDetails = () => {
  const params = useParams()
  const { id } = params
  const [details, setDetails] = useState({})

  const getBookingDetail = async () => {
    const response = await clientService.get(`front/booking-detail/${id}`)
    setDetails(response?.data?.data)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getBookingDetail()
  }, [])

  if (!details.id) {
    return <div>Loading</div>
  }

  const sites = details.booking_info.Tickets.map((ticket) => {
    return `${ticket.SeatRowId}${ticket.SeatNumber}`
  })

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-10">
          <div className="max-width-500">
            <div className="mt-4">
              <h3 className="text-white text-center">Your Ticket</h3>
            </div>
            <Card
              bg={'dark'}
              key={'dark'}
              text={'white'}
              style={{ borderRadius: 10 }}
              className="mb-2"
            >
              <Card.Header style={{ fontSize: 20 }}>
                {details.booking_info.Tickets[0].MovieName}
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <Row className="box-shadow">
                    <Col>
                      <div className="text-white">
                        Date & Time:{' '}
                        {moment(details?.session?.show_date_time).format(
                          'YYYY-MM-DD hh:mm',
                        )}
                      </div>

                      <div className="text-white mt-4">
                        Screen Name: {details?.session?.screen}
                      </div>

                      <div className="text-white mt-4">
                        Seats: {sites.join(', ')}
                      </div>

                      <div className="text-white mt-4">Booking Id: {id}</div>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            <Row className="box-shadow mt-4">
              <Col style={{ textAlign: 'center' }}>
                <QRCode value={id} style={{ height: 250, width: 250 }} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderDetails

//do not delete

// <div className="container d-flex justify-content-center">
//   <div style={{ flex: 1 }}>
//     <div className="max-width-500">
//       <div className="mt-4 ">
//         <h3 className="text-white text-center">Your Ticket</h3>
//       </div>
//       <Card
//         bg={"dark"}
//         key={"dark"}
//         text={"white"}
//         style={{ width: "100%", borderRadius: 10 }}
//         className="mb-2"
//       >
//         <Card.Header style={{ fontSize: 20 }}>
//           {movieData?.info?.Title}
//         </Card.Header>
//         <Card.Body>
//           <Card.Text>
//             <Row className="box-shadow">
//               <Col className="col-5">
//                 <img
//                   alt="movie-poster"
//                   src={movieData?.info?.GraphicUrl}
//                   height={150}
//                   width={100}
//                 ></img>
//               </Col>
//               <Col>
//                 <div className="text-white">Date & Time : 20-10-2020</div>
//                 <div className="text-white mt-4">Seats : C1,C2</div>
//                 <div className="text-white mt-4">
//                   Booking Id : {vistaBookingId}{" "}
//                 </div>
//               </Col>
//             </Row>
//           </Card.Text>
//         </Card.Body>
//       </Card>

//       <Row className="box-shadow mt-4">
//         <Col style={{ textAlign: "center" }}>
//           <QRCode
//             value={vistaBookingId}
//             style={{ height: 250, width: 250 }}
//           />
//         </Col>
//       </Row>
//     </div>
//   </div>
// </div>
