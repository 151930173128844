/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
//Import Library's
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//Import CSS
import '../Styles/common.css'
import '../Styles/profile.css'

//Import components ,firebase auth and redux-store
import { auth } from '../utils/firebaseApp'
import { clientService } from '../utils/Service'
import { logoutUser } from '../store/slices/authSlice'

const Profile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isUserDetails = useSelector((state) => state.auth.authUser)

  const [showUserModal, setShowUserModal] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showUserModal])

  const dropdownData = [
    {
      id: 1,
      title: 'Senor (Sr)',
    },
    {
      id: 2,
      title: 'Miss (MS)',
    },
    {
      id: 3,
      title: 'The Honourable (TH)',
    },
  ]

  const [formData, setFormdata] = useState({
    name: isUserDetails.name,
    email: isUserDetails.email,
    title: isUserDetails.title,
  })

  const handleChange = (e) => {
    setFormdata({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoader(true)

      const res = await clientService.post('front/user/profile', formData)
      if (res.status) {
        setShowUserModal(false)
        alert('Profile has been updated.')
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleNavigation = (page) => {
    navigate(page)
  }

  const handleEditProfile = () => {
    setShowUserModal(true)
  }

  const handleLogout = async () => {
    try {
      setLoader(true)
      await auth.signOut()
      dispatch(logoutUser())
      await localStorage.removeItem('accessToken')
      window.location.href = '/'
    } catch (error) {
      console.error('Error during logout:', error)
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="container d-flex flex-column align-items-center justify-content-center p-2">
      <div className="profile-header-view">
        <div className="d-flex gap-3 align-items-center">
          <img
            src={require('../Assets/Images/profileUser.png')}
            className="user-logo white-image"
            alt="profile"
          />
          <div className="text-white d-flex flex-column">
            <span className="fs-small text-grey">Profile</span>
            <span>{isUserDetails.name ? isUserDetails.name : 'User Name'}</span>
          </div>
        </div>

        <img
          src={require('../Assets/Images/edit-text.png')}
          className="edit-logo white-image"
          alt="edit"
          onClick={handleEditProfile}
        />
      </div>
      <div className="user-info-view">
        <div className="horizontal-divider"></div>
        <div
          className="user-content-title-view"
          onClick={() => handleNavigation('/payment-details')}
        >
          <div className="d-flex align-items-center gap-4">
            <img
              src={require('../Assets/Images/wallet.png')}
              alt=""
              className="white-image"
              height={30}
              width={30}
            />
            <div>Payment Details</div>
          </div>

          <img
            src={require('../Assets/Images/next.png')}
            alt=""
            className="next-img white-image"
          />
        </div>
        <div
          className="user-content-title-view"
          onClick={() => handleNavigation('/booking')}
        >
          <div className="d-flex align-items-center gap-4">
            <img
              src={require('../Assets/Images/ticket.png')}
              alt=""
              className="white-image"
              height={30}
              width={30}
            />
            <div>Bookings</div>
          </div>

          <img
            src={require('../Assets/Images/next.png')}
            alt=""
            className="next-img white-image"
          />
        </div>
        <div
          className="user-content-title-view"
          onClick={() => handleNavigation('/food-orders')}
        >
          <div className="d-flex align-items-center gap-4">
            <img
              src={require('../Assets/Images/restaurant.png')}
              alt=""
              className="white-image"
              height={30}
              width={30}
            />
            <div>Food Orders</div>
          </div>

          <img
            src={require('../Assets/Images/next.png')}
            alt=""
            className="next-img white-image"
          />
        </div>
        {/*  <div
          className="user-content-title-view"
          onClick={() => handleNavigation("/privacy-policy")}
        >
          <div className="d-flex align-items-center gap-4">
            <img
              src={require("../Assets/Images/file.png")}
              alt=""
              className="white-image"
              height={30}
              width={30}
            />
            <div>Privacy Policy</div>
          </div>

          <img
            src={require("../Assets/Images/next.png")}
            alt=""
            className="next-img white-image"
          />
        </div> */}
      </div>
      <div className="logout-view">
        <button className="logout-btn" onClick={handleLogout}>
          Log Out
        </button>
      </div>
      <Modal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        className="profile-modal-view"
        centered
      >
        <div className="profile-modal-content">
          <Modal.Header closeButton closeVariant="white" className="bg-black">
            <Modal.Title className="text-white">Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-black profile-body">
            <form onSubmit={handleSubmit} className="mt-3">
              <select
                name="title"
                className="input form-select"
                aria-label="Default select example"
                value={formData.title}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Title
                </option>
                {dropdownData.map((item) => (
                  <option value={item.title} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="input"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  className="input"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <button type="submit" className="profile-btn-view">
                  {loader ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}

export default Profile
