import React, { useEffect } from 'react'
import '../Styles/contactUs.css'

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="container  contect-view my-4">
        <div className="contact-us-view">
          <h2 className="text-color head-view">Contact Us</h2>
          <div className="horizontal-line my-3"></div>
          <h5 className="phone-number">FoMo Cinemas: 03 9000 7440 </h5>
          <p className="text-color lh-sm fs-6">
            Before submitting your enquiry, please be sure to read through our
            FAQ.
          </p>
          <p className="text-color lh-1 fs-6">
            For urgent, ticket-related enquiries, please contact our team at{' '}
            <a href="mailto:dutymanager@fomocinemas.com.au">
              dutymanager@fomocinemas.com.au
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default ContactUs
