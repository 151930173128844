/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import QRCode from "qrcode.react";
import { clientService } from "../utils/Service";
import { Card, Col, Row } from "react-bootstrap";
import moment from "moment";

const BookingSuccess = () => {
  const [searchParams] = useSearchParams();
  const [details, setDetails] = useState({});
  const navigate = useNavigate();

  const getBookingDetail = async () => {
    const sessionId = searchParams.get("sessionId");
    const response = await clientService.get(
      `front/booking-detail-session/${sessionId}`
    );
    if (response?.data?.data) {
      setDetails(response?.data?.data);
    } else {
      navigate("/404");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getBookingDetail();
    }, 10000);
  }, []);

  if (!details.id) {
    return (
      <div
        style={{
          color: "#fff",
          padding: "100px 20px",
          textAlign: "center",
        }}
      >
        <h2>
          Thank you for booking at FoMo. Please wait while we confirm your
          tickets.{" "}
        </h2>
        <h6 style={{ marginTop: 50 }}>Do not refresh the page.</h6>
      </div>
    );
  }

  const sites = details.booking_info.Tickets.map((ticket) => {
    return `${ticket.SeatRowId}${ticket.SeatNumber}`;
  });

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-10">
          <div className="max-width-500">
            <div className="mt-4">
              <h3 className="text-white text-center">Your Ticket</h3>
            </div>
            <Card
              bg={"dark"}
              key={"dark"}
              text={"white"}
              style={{ borderRadius: 10 }}
              className="mb-2"
            >
              <Card.Header style={{ fontSize: 20 }}>
                {details.booking_info.Tickets[0].MovieName}
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <Row className="box-shadow">
                    <Col>
                      <div className="text-white">
                        Date & Time:{" "}
                        {moment(details?.session?.show_date_time).format(
                          "YYYY-MM-DD hh:mm"
                        )}
                      </div>

                      <div className="text-white mt-4">
                        Screen Name: {details?.session?.screen}
                      </div>

                      <div className="text-white mt-4">
                        Seats: {sites.join(", ")}
                      </div>

                      <div className="text-white mt-4">
                        Booking Id: {details.vista_booking_id}
                      </div>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            <Row className="box-shadow mt-4">
              <Col style={{ textAlign: "center" }}>
                <QRCode
                  value={details.vista_booking_id}
                  style={{ height: 250, width: 250 }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSuccess;
