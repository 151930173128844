/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../Styles/food.css";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

const FoodDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { cid, scid } = params;
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const foodData = useSelector((state) => state.food.foodData);
  if (!foodData?.categories) {
    return <div>Loading</div>;
  }
  const foodCat = foodData.categories.find((item) => item.id == cid);
  const subCategory = foodCat.sub_category.find((item) => item.id == scid);

  const handleBackClick = () => {
    navigate(-1);
  };

  if (!foodCat) {
    window.alert(
      "Please Select Category From Food Page First. Going back to the food page."
    );
    navigate("/food");
    return <></>;
  }

  return (
    <div className="container">
      <div className="my-3">
        <img
          alt=""
          src={require("../Assets/Images/left-white-arrow.png")}
          className="previous-btn"
          onClick={handleBackClick}
          height={30}
          width={30}
        />
      </div>
      <div className="mt-3 main-subcategory-view">
        <h4 className="food-text">{subCategory.name}</h4>
        <div className="subfood-view">
          {subCategory?.products?.map((subItem, subIndex) => {
            return (
              <Row>
                <Col
                  xs={4}
                  md={3}
                  lg={2}
                  sm={4}
                  key={`sub-${subIndex}`}
                  className=""
                >
                  <div className="img-container">
                    <img
                      alt=""
                      src={
                        subItem?.icon
                          ? subItem?.icon
                          : require("../Assets/Images/fomo-food-behalf-poster.png")
                      }
                      className="sub-food-image cursor-pointer"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="sub-foodText fs-6">{subItem.name}</div>
                  <div className="dis-text">{subItem.description}</div>
                  <div className="sub-foodText">$ {subItem.price}</div>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FoodDetails;
