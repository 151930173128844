/* eslint-disable no-unused-vars */
//library
import React from 'react'
import { Route, Routes } from 'react-router-dom'

//pages
import Food from '../pages/Food'
import Login from '../pages/Login'
import Profile from '../pages/Profile'
import DashBoard from '../pages/DashBoard'
import NowShowing from '../pages/NowShowing'
import FoodDetails from '../pages/FoodDetails'
import MovieDetails from '../pages/MovieDetails'
import BookingDetails from '../pages/BookingDetails'

//components
import Faq from '../components/Faq'
import About from '../components/About'
import MyAccount from '../pages/MyAccount'
import Help from '../pages/ProfilePages/Help'
import CoomingSoon from '../pages/coomingSoon'
import ContactUs from '../components/contactus'
import OrderDetails from '../pages/OrderDetails'
import Booking from '../pages/ProfilePages/Booking'
import TimeAndSeats from '../components/TimeAndSeats'
import MovieListsByDay from '../pages/MovieListsByDay'
import Disclaimer from '../pages/ProfilePages/Disclaimer'
import PrivacyPolicy from '../pages/ProfilePages/PrivacyPolicy'
import PaymentDetails from '../pages/ProfilePages/PaymentDetails'
import GiftCard from '../pages/GiftCard'
import GiftCardBuyerDetails from '../pages/GiftCardBuyerDetails'
import FoodOrders from '../pages/ProfilePages/FoodOrders'
import FoodOrderDetails from '../pages/ProfilePages/FoodOrderDetails'
import PrivateRoute from './PrivateRoute'
import BookingFail from '../pages/BookingFail'
import PaymentFail from '../pages/PaymentFail'
import BookingSuccess from '../pages/BookingSuccess'
import PageNotFound from '../pages/PageNotFound'
import FlimByDay from '../pages/FlimByDay'

const Navigation = () => {
  return (
    <>
      <Routes>
        <Route index element={<DashBoard />} />
        <Route path="login" element={<Login />} />
        <Route path="/movie-details/:index" element={<MovieDetails />} />
        <Route path="/movie-details/:index/:name" element={<MovieDetails />} />
        <Route path="cooming-soon" element={<CoomingSoon />} />
        <Route path="time-and-seats" element={<TimeAndSeats />} />
        <Route path="about" element={<About />} />
        <Route path="faq" element={<Faq />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="food" element={<Food />} />
        <Route path="food-details/:cid/:scid" element={<FoodDetails />} />
        <Route path="now-showing" element={<NowShowing />} />
        <Route path="movie-lists" element={<MovieListsByDay />} />
        <Route path="booking/fail" element={<BookingFail />} />
        <Route path="booking/cancel" element={<PaymentFail />} />
        <Route path="/film-by-day/:index" element={<FlimByDay />} />

        <Route path="help" element={<Help />} />
        <Route path="terms-&-conditions" element={<Disclaimer />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="gift-card" element={<GiftCard />} />
        <Route path="personal-details" element={<GiftCardBuyerDetails />} />

        <Route
          path="booking"
          element={
            <PrivateRoute>
              <Booking />
            </PrivateRoute>
          }
        />
        <Route
          path="booking-details"
          element={
            <PrivateRoute>
              <BookingDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="my-account"
          element={
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="order-details/:id"
          element={
            <PrivateRoute>
              <OrderDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="payment-details"
          element={
            <PrivateRoute>
              <PaymentDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="food-orders"
          element={
            <PrivateRoute>
              <FoodOrders />
            </PrivateRoute>
          }
        />
        <Route
          path="food-order-details"
          element={
            <PrivateRoute>
              <FoodOrderDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="booking/success"
          element={
            // <PrivateRoute>
            <BookingSuccess />
            // </PrivateRoute>
          }
        />
        <Route
          path="404"
          element={
            // <PrivateRoute>
            <PageNotFound />
            // </PrivateRoute>
          }
        />
      </Routes>
    </>
  )
}

export default Navigation
