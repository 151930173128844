/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import '../Styles/home.css'
import { Grid, Skeleton } from '@mui/material'
import '../Styles/common.css'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const DashBoardPreview = () => {
  const [selectedDay, setSelectedDay] = useState('')
  const navigate = useNavigate()
  const moviedata = useSelector((state) => state.movie.movieData)
  // const sectionData = useSelector((state) => state.movie.sectionData)
  // const sliderData = useSelector((state) => state.movie.sliderData)

  const [itemHeight, setItemHeight] = useState(500)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setItemHeight(350)
      } else {
        setItemHeight(500)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value)
  }

  const handleNavigation = (index) => {
    navigate('movie-details/' + `${index}`)
  }

  return (
    <div className="dashboard">
      <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <Skeleton
            variant="rectangular"
            sx={{ bgcolor: 'grey.600' }}
            width="100%"
            height={itemHeight}
            // className="m"
          />
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          {/* <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden"></span> */}
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          {/* <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden"></span> */}
        </button>
      </div>

      {/* <div className="d-flex align-items-center mt-4">
        <div className="d-flex flex-wrap align-items-center gap-4 mx-auto store-view">
          <div className="store-text-view">
            <h6 className="text-white">DOWNLOAD NOW TO DINE-IN:</h6>
            <p className="text-white">You can only order food and drink</p>
            <p className="text-white">to your seat thorugh our App</p>
          </div>
          <div>
            <a href="https://www.apple.com/app-store/" target="">
              <img
                alt="apple-store"
                src={require('../Assets/Images/store-apple.png')}
                className="app-store-image"
              />
            </a>
          </div>
          <div>
            <a href="https://play.google.com/store" target="_blank">
              <img
                alt="android-google-play"
                src={require('../Assets/Images/android.png')}
                className="google-play-image"
              />
            </a>
          </div>
        </div>

        <div className="container search-view">
          <div>
            <select
              name="movies"
              id="movies"
              onChange={(e) => handleNavigation(e.target.value)}
            >
              <option value="">Select by movie</option>
              {moviedata?.map((movieItem, index) => (
                <option key={`movielist-${index}`} value={index}>
                  {movieItem.info.Title}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select value={selectedDay} onChange={handleDayChange} id="days">
              <option value="">Select by day</option>
              {days.map((day, index) => (
                <option key={`day-${index}`} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div> */}

      <Container maxwidth="sm" className="mt-5">
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6, 7, 8]?.map((movieItem) => {
            return (
              <Grid item xs={6} md={4} lg={3} sm={6} key={`movie_${movieItem}`}>
                <Skeleton
                  variant="rectangular"
                  sx={{ bgcolor: 'grey.600' }}
                  width="100%"
                  height={410}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </div>
  )
}

export default DashBoardPreview
