import React from "react";
import "../Styles/fail.css";

const PageNotFound = () => {
  return (
    <div className="position-absolute top-50 start-50 translate-middle">
      <div className="text-white text-center">
        <h1>404</h1>
        <h5 className="fs-5 ">Page Not Found</h5>
      </div>
    </div>
  );
};

export default PageNotFound;
