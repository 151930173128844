//Import library's
import {
  CardActionArea,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//Import CSS
import { Carousel } from "react-bootstrap";
import "../Styles/common.css";
import "../Styles/home.css";

const DashBoard = () => {
  const navigate = useNavigate();
  const moviedata = useSelector((state) => state.movie.movieData);
  const sectionData = useSelector((state) => state.movie.sectionData);
  const sliderData = useSelector((state) => state.movie.sliderData);

  const [itemHeight, setItemHeight] = useState(860);
  const [selectedDay, setSelectedDay] = useState("");
  const [filteredMovies, setFilteredMovies] = useState([]);

  const filteredMoviesByOrderId = [...filteredMovies].sort((a, b) => {
    const orderIdA = a.info?.orderId;
    const orderIdB = b.info?.orderId;

    if (orderIdA === null && orderIdB === null) return 0;
    if (orderIdA === null) return 1;
    if (orderIdB === null) return -1;

    return orderIdA - orderIdB;
  });

  const sortedMoviesByAlphabetical = [...moviedata]
    .filter((movie) => !movie.info.IsComingSoon)
    .sort((a, b) => {
      const titleA = a.info.Title.toUpperCase();
      const titleB = b.info.Title.toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    setFilteredMovies(moviedata);
  }, [moviedata]);

  const handleDayChange = (event) => {
    const selectedDayValue = event.target.value;
    navigate(`/film-by-day/${encodeURIComponent(selectedDayValue)}`);
  };

  const sanitizeHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText;
  };

  const handleNavigation = (index) => {
    navigate("movie-details/" + `${index}`);
  };

  const handleNavigateToFood = () => {
    navigate("/food");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setItemHeight(350);
      } else {
        setItemHeight(550);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createUrl = (title) => {
    return encodeURIComponent(title);
  };
  return (
    <div className="dashboard">
      <Carousel>
        {sliderData?.data?.map((item, index) => {
          return (
            <Carousel.Item key={`carousel-item-${item.id}`} interval={3000}>
              <img
                src={item.image_url}
                className="d-block m-auto"
                alt={`Slide ${item.id}`}
                style={{
                  maxWidth: "100%",
                  objectFit: "fill",
                  maxHeight: itemHeight,
                }}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>

      <div className="d-flex align-items-center mt-4">
        <div className="d-flex align-items-center mx-auto store-view">
          <div className="store-text-view mx-2">
            <h6 className="text-white pc-text-view">
              DOWNLOAD NOW TO DINE-IN:
            </h6>
            <p className="text-white pc-text-view">
              You can only order food and drink to your seat through our App
            </p>
          </div>

          <div className="mobile-text-view">
            <div>
              <span className="text-white">DOWNLOAD NOW TO DINE-IN:</span>
            </div>
            <span className="text-white">
              You can only order food and drink to your seat through our App
            </span>
          </div>

          <div className="d-flex align-items-center gap-3">
            <div>
              <span>
                <a href="https://fomofoodapp.page.link/app" target="_blank">
                  <img
                    alt="apple-store"
                    src={require("../Assets/Images/store-apple.png")}
                    className="app-store-image"
                  />
                </a>
              </span>
            </div>
            <div>
              <span>
                <a href="https://fomofoodapp.page.link/app" target="_blank">
                  <img
                    alt="android-google-play"
                    src={require("../Assets/Images/android.png")}
                    className="google-play-image"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="container search-view">
          <select
            name="movies"
            id="movies"
            onChange={(e) => handleNavigation(e.target.value)}
            className="form-select"
            aria-label="Default select example"
          >
            <option value="">Select by movie</option>
            {sortedMoviesByAlphabetical?.map((movieItem, index) => (
              <option
                key={`movielist-${index}`}
                value={`${movieItem.ScheduledFilmId}/${createUrl(
                  movieItem.info.Title
                )}`}
                className=""
              >
                {movieItem.info.Title}
              </option>
            ))}
          </select>
          <select
            value={selectedDay}
            onChange={(e) => handleDayChange(e)}
            id="days"
            className="form-select"
            aria-label="Default select example"
          >
            <option value="">Session Times by Day</option>
            {days.map((day) => (
              <option key={`${day}`} value={day}>
                {day}
              </option>
            ))}
          </select>
          {selectedDay !== "" && (
            <div>
              <img
                alt="clear"
                src={require("../Assets/Images/cross.png")}
                height={30}
                width={30}
                onClick={() => {
                  setSelectedDay("");
                  setFilteredMovies(moviedata);
                }}
              />
            </div>
          )}
        </div>
      </div>

      <Container className="mb-3 mt-2">
        {selectedDay && (
          <h4 className="text-white">Films showing on {selectedDay}</h4>
        )}
        <Grid container spacing={2}>
          {filteredMoviesByOrderId
            ?.filter((movieItem) => !movieItem.info.IsComingSoon)
            ?.map((movieItem, index) => {
              return (
                <Grid item xs={6} md={4} lg={3} sm={6} key={`movie_${index}`}>
                  <Card
                    onClick={() =>
                      handleNavigation(
                        `${movieItem.ScheduledFilmId}/${createUrl(
                          movieItem.info.Title
                        )}`
                      )
                    }
                  >
                    <CardActionArea>
                      <CardMedia
                        className="responsive-img"
                        component="img"
                        image={
                          movieItem.info.GraphicUrl
                            ? movieItem.info.GraphicUrl
                            : require("../Assets/Images/movie-poster-design.png")
                        }
                        alt={movieItem.info.Title}
                      />
                      <CardContent className="card-content">
                        <Typography
                          variant="h5"
                          component="div"
                          className="fs-6 card-content-text"
                        >
                          {movieItem.info.Title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
        </Grid>

        <div className="container mt-5">
          <div
            className="responsive-container"
            style={{
              backgroundImage: `url(${sectionData?.food_offer?.image_path})`,
            }}
            onClick={handleNavigateToFood}
          >
            <div className="content">
              <p className="special-text">
                {sectionData?.food_offer?.pre_title}
              </p>
              <p className="middle-text">{sectionData?.food_offer?.title}</p>
              <p className="bottom-text">
                {sectionData?.food_offer?.sub_title}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DashBoard;
