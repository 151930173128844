import { createSlice } from '@reduxjs/toolkit'
import { clientService } from '../../utils/Service'

// Define an initial state for the moive data
const movieInitialState = {
  movieData: [],
  isComingSoonMovie: false,
  isLoading: true,
  sectionData: null,
  sliderData: null,
  coomingSoonMovieData: [],
  isLoadingComingSoon: true,
}

const movieSlice = createSlice({
  name: 'movie',
  initialState: movieInitialState,
  reducers: {
    setMovieData: (state, action) => {
      state.movieData = action.payload
      state.isLoading = false
    },
    setCoomingSoonMovieData: (state, action) => {
      state.coomingSoonMovieData = action.payload
      state.isLoading = false;
      state.isLoadingComingSoon = false
    },
    setComingSoonMovie: (state, action) => {
      state.isComingSoonMovie = action.payload
    },
    setSectionData: (state, action) => {
      state.sectionData = action.payload
    },
    setSliderData: (state, action) => {
      state.sliderData = action.payload
    },
  },
})

export const getMovieData = () => async (dispatch) => {
  try {
    const res = await clientService.get('front/film/with-show')
    dispatch(movieSlice.actions.setMovieData(res?.data?.data))
  } catch (error) {
    console.error('Error fetching Movie data:', error)
  }
}

export const getCoommingSoonMovieData = () => async (dispatch) => {
  try {
    const res = await clientService.get('front/coming-soon-movies')
    dispatch(movieSlice.actions.setCoomingSoonMovieData(res?.data?.data))
  } catch (error) {
    console.error('Error fetching Movie data:', error)
  }
}

export const getSectionData = () => async (dispatch) => {
  try {
    const res = await clientService.get('home/section/details')
    dispatch(movieSlice.actions.setSectionData(res?.data?.data))
  } catch (error) {
    console.error('Error fetching Movie data:', error)
  }
}

export const getSliderData = () => async (dispatch) => {
  try {
    const res = await clientService.get('front/sliders')
    dispatch(movieSlice.actions.setSliderData(res?.data))
  } catch (error) {
    console.error('Error fetching Movie data:', error)
  }
}

export const {
  setMovieData,
  setComingSoonMovie,
  setSliderData,
  setSectionData,
  setCoomingSoonMovieData,
} = movieSlice.actions

export default movieSlice.reducer
