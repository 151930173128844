import React, { useEffect, useState } from 'react'
import '../../Styles/privacyPolicy.css'
import { clientService } from '../../utils/Service'

const PrivacyPolicy = () => {
  const [content, setContent] = useState('')
  const [loader, setLoder] = useState(false)
  useEffect(() => {
    getPrivacyPage()
  }, [])

  const getPrivacyPage = async () => {
    setLoder(true)
    const res = await clientService.post('front/content', {
      title: 'Privacy Policy',
    })
    setContent(res.data.data.content)
    setLoder(false)
  }
  return (
    <div className="white-bg">
      <div className="container">
        {loader ? (
          <>
            <div className="aboutusloder-view">
              <div className="spinner-border text-dark" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        )}
      </div>
    </div>
  )
}

export default PrivacyPolicy
