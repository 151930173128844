//library's
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Skeleton } from "@mui/material";
import "../Styles/movieDetail.css";
import BookingDetails from "./BookingDetails";

const MovieDetail = () => {
  const params = useParams();
  const { index } = params;

  const isLoadingComingSoon = useSelector(
    (state) => state.movie.isLoadingComingSoon
  );

  const movies = useSelector((state) => state.movie.movieData);
  const coomingSoons = useSelector((state) => state.movie.coomingSoonMovieData);
  const movieDetails = movies.find((item) => item.ScheduledFilmId == index);
  const coomingSoon = coomingSoons.find((item) => item.film_id == index);
  const [showSeatModal, setShowSeatModal] = useState(false);
  const navigate = useNavigate();
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [pageState, setPageState] = useState({
    selectedDate: new Date(),
    selectedTime: null,
    selectedSeat: 1,
    selectedCategory: null,
    ticketInfo: [],
    seatsList: [...Array(10).keys()],
    showSeating: false,
    sessionId: "",
    ScreenName: "",
    ticketTypeCode: "",
    tickets: {},
  });

  const {
    selectedDate,
    selectedTime,
    selectedSeat,
    selectedCategory,
    ticketInfo,
    seatsList,
    showSeating,
    price,
    tickets,
  } = pageState;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate, showSeatModal]);

  const videoId = useMemo(() => {
    if (movieDetails?.info?.TrailerUrl || coomingSoon?.info?.TrailerUrl) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match =
        movieDetails?.info?.TrailerUrl.match(regExp) ||
        coomingSoon?.info?.TrailerUrl.match(regExp);
      return match && match[7].length === 11 ? match[7] : "";
    }
    return "";
  }, [movieDetails?.info?.TrailerUrl || coomingSoon?.info?.TrailerUrl]);

  const toggleCalendar = () => {
    setCalendarOpen(!isCalendarOpen);
  };

  const handleTimeSelection = async (item) => {
    setPageState({
      ...pageState,
      selectedTime: item.time,
      sessionId: item.sessionId,
      ScreenName: item.ScreenName,
      showSeating: true,
    });
  };

  const toggleSeatModal = () => {
    setShowSeatModal(!showSeatModal);
  };

  const handleSeatSelection = (seat) => {
    setPageState({
      ...pageState,
      selectedSeat: seat,
    });
  };

  const showTimesByDate = {};
  movieDetails?.showTimes?.forEach((showTime) => {
    const date = moment(showTime?.time).format("YYYY-MM-DD");
    if (!showTimesByDate[date]) {
      showTimesByDate[date] = [];
    }
    showTimesByDate[date].push(showTime);
  });

  //Show time set in accending order
  const sortedDates = Object.keys(showTimesByDate).sort(
    (a, b) => new Date(a) - new Date(b)
  );

  const convertMinutesToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hours ${remainingMinutes} minutes`;
  };

  const sanitizeHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText;
  };

  if (showSeating) {
    return <BookingDetails movieDetails={movieDetails} pageState={pageState} />;
  }

  const handleMinusClick = (ticket) => {
    let _tickets = { ...tickets };
    if (_tickets[ticket.TicketTypeCode]) {
      _tickets[ticket.TicketTypeCode] -= 1;
    } else {
      _tickets[ticket.TicketTypeCode] = 0;
    }

    setPageState({
      ...pageState,
      tickets: _tickets,
    });
  };

  const handlePlusClick = (ticket) => {
    let _tickets = { ...tickets };
    if (_tickets[ticket.TicketTypeCode]) {
      _tickets[ticket.TicketTypeCode] += 1;
    } else {
      _tickets[ticket.TicketTypeCode] = 1;
    }
    setPageState({
      ...pageState,
      tickets: _tickets,
    });
  };

  const combinedCast = [
    ...(movieDetails?.info?.cast || []),
    ...(coomingSoon?.info?.cast || []),
  ];

  return (
    <div className="container container-background mt-2">
      <div className="card-body">
        <div className="row">
          <div className="col-md-4">
            <h2 className="font-weight-bold text-white">
              {movieDetails || coomingSoon ? (
                movieDetails?.info?.Title || coomingSoon?.info?.Title
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", bgcolor: "grey.600" }}
                />
              )}
            </h2>

            {coomingSoon?.info?.OpeningDate && (
              <div className="mobile-view text-white">
                <span className="fs-5">
                  Opening Date :{" "}
                  {moment(coomingSoon?.info?.OpeningDate).format(
                    "dddd Do MMMM "
                  )}
                </span>
              </div>
            )}

            <div className="mt-4 mobile-view">
              <Col sm={12} md={12} lg={12} xs={12}>
                {sortedDates[0] ? (
                  <h4 className="heading">Select Session</h4>
                ) : null}

                <div className="">
                  {sortedDates.map((date, index) => {
                    return (
                      <div key={`date-${index}`} className="mb-4">
                        <h6 className="text-white date-text">
                          {moment(date).format("dddd Do MMMM ")}
                        </h6>
                        <div className="time-section">
                          {showTimesByDate[date]
                            .sort((a, b) => new Date(a.time) - new Date(b.time))
                            .map((item, index) => (
                              <div className="" key={`time-${index}`}>
                                <div
                                  key={`time-${index}`}
                                  className={`time-button ${
                                    selectedTime === item?.time
                                      ? "selected-time"
                                      : "not-selected-time"
                                  }`}
                                  onClick={() => handleTimeSelection(item)}
                                >
                                  <div className="time-button-content">
                                    {moment(item?.time).format("HH:mm")}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </div>

            <div className="img-responsive mobile-view">
              <img
                src={
                  movieDetails?.info?.GraphicUrl ||
                  coomingSoon?.info?.GraphicUrl
                    ? movieDetails?.info?.GraphicUrl ||
                      coomingSoon?.info?.GraphicUrl
                    : require("../Assets/Images/movie-poster-design.png")
                }
                alt="Movie Poster"
                height={480}
                width={350}
                className="custom-responsive-image"
              />
            </div>

            <p className="text-white">
              {convertMinutesToHoursAndMinutes(
                movieDetails?.info?.RunTime || coomingSoon?.info?.RunTime
              )}{" "}
              | Rated {movieDetails?.info?.Rating || coomingSoon?.info?.Rating}
            </p>

            <div
              className="text-white description-text mobile-view"
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(
                  movieDetails?.info?.Synopsis || coomingSoon?.info?.Synopsis
                ),
              }}
            ></div>

            {coomingSoon?.info?.OpeningDate && (
              <div className="pc-view text-white">
                <span className="fs-5">
                  Opening Date :{" "}
                  {moment(coomingSoon?.info?.OpeningDate).format(
                    "dddd Do MMMM "
                  )}
                </span>
              </div>
            )}

            <div className="mt-4 pc-view">
              <Col sm={12} md={12} lg={12} xs={12}>
                {sortedDates[0] ? (
                  <h3 className="heading">Select Session</h3>
                ) : null}

                <div className="">
                  {sortedDates.map((date, index) => (
                    <div key={`date-${index}`} className="mb-4">
                      <h6 className="text-white date-text">
                        {moment(date).format("dddd Do MMMM ")}
                      </h6>
                      <div className="time-section">
                        {showTimesByDate[date]
                          .sort((a, b) => new Date(a.time) - new Date(b.time))
                          .map((item, index) => {
                            return (
                              <div className="" key={`time-${index}`}>
                                <div
                                  className={`time-button ${
                                    selectedTime === item?.time
                                      ? "selected-time"
                                      : "not-selected-time"
                                  }`}
                                  onClick={() => handleTimeSelection(item)}
                                >
                                  <div className="time-button-content">
                                    {moment(item?.time).format("HH:mm")}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <div
              className="mb-4 mx-auto pc-view"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="img-responsive col-md-5">
                <img
                  src={
                    movieDetails?.info?.GraphicUrl ||
                    coomingSoon?.info?.GraphicUrl
                      ? movieDetails?.info?.GraphicUrl ||
                        coomingSoon?.info?.GraphicUrl
                      : require("../Assets/Images/movie-poster-design.png")
                  }
                  alt="Movie Poster"
                  height={280}
                  width={350}
                  className="custom-responsive-image"
                />
              </div>
              <div
                className="text-white description-text col-md-7"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(
                    movieDetails?.info?.Synopsis || coomingSoon?.info?.Synopsis
                  ),
                }}
              ></div>
            </div>

            {combinedCast.length > 0 ? (
              <div>
                <h5 className="text-white">Movie Cast</h5>
                <div className="d-flex flex-wrap gap-3 my-3">
                  {combinedCast.map((item, index) => {
                    return (
                      <div key={`cast-${index}`} className="cast-view">
                        <div className="text-white">
                          {item.first_name} {item.last_name}
                        </div>
                        <div className="">{item.type}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {movieDetails?.info?.GenreId ||
            coomingSoon?.GenreName1 ||
            coomingSoon?.GenreName2 ||
            coomingSoon?.GenreName3 ||
            movieDetails?.info?.GenreId2 ||
            movieDetails?.info?.GenreId3 ? (
              <h5 className="text-white">Genre</h5>
            ) : null}

            <div className="d-flex flex-wrap gap-3 my-3">
              {movieDetails?.info?.GenreId || coomingSoon?.GenreName1 ? (
                <div className="genre-view">
                  <div className="text-white text-center">
                    {movieDetails?.info?.GenreId || coomingSoon?.GenreName1}
                  </div>
                </div>
              ) : null}

              {movieDetails?.info?.GenreId2 || coomingSoon?.GenreName2 ? (
                <div className="genre-view">
                  <div className="text-white text-center">
                    {movieDetails?.info?.GenreId2 || coomingSoon?.GenreName2}
                  </div>
                </div>
              ) : null}

              {movieDetails?.info?.GenreId3 || coomingSoon?.GenreName3 ? (
                <div className="genre-view">
                  <div className="text-white text-center">
                    {movieDetails?.info?.GenreId3 || coomingSoon?.GenreName3}
                  </div>
                </div>
              ) : null}
            </div>
            {movieDetails?.info?.TrailerUrl || coomingSoon?.info?.TrailerUrl ? (
              <div
                style={{
                  paddingBottom: "56.25%",
                  position: "relative",
                  display: "block",
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://youtube.com/embed/${videoId}?autoplay=0`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  style={{ position: "absolute", top: 0, left: 0 }}
                ></iframe>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieDetail;
