/* eslint-disable no-useless-concat */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
//Import library's
import {
  CardActionArea,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//Import CSS
import "../Styles/common.css";
import "../Styles/home.css";

const NowShowing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const moviedata = useSelector((state) => state.movie.movieData);

  const nowShowing = moviedata.filter(
    (movieItem) => movieItem.info.IsComingSoon === false
  );

  const filteredMoviesByOrderId = [...nowShowing].sort((a, b) => {
    const orderIdA = a.info?.orderId;
    const orderIdB = b.info?.orderId;

    if (orderIdA === null && orderIdB === null) return 0;
    if (orderIdA === null) return 1;
    if (orderIdB === null) return -1;

    return orderIdA - orderIdB;
  });

  const handleNavigation = (index) => {
    navigate("/movie-details/" + `${index}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const createUrl = (title) => {
    return encodeURIComponent(title);
  };
  return (
    <div className="dashboard">
      <Container className="mb-4">
        <Grid container>
          <Grid item className="mb-2">
            {/* <h4 className="mt-3 text-white">Now Showing (A-Z)</h4> */}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {filteredMoviesByOrderId?.map((movieItem, index) => {
            return (
              <Grid item xs={6} md={4} lg={3} sm={6} key={`movie_${index}`}>
                <Card
                  onClick={() =>
                    handleNavigation(
                      `${movieItem.ScheduledFilmId}/${createUrl(
                        movieItem.info.Title
                      )}`
                    )
                  }
                >
                  <CardActionArea>
                    <CardMedia
                      className="responsive-img"
                      component="img"
                      height="410"
                      image={
                        movieItem.info.GraphicUrl
                          ? movieItem.info.GraphicUrl
                          : require("../Assets/Images/movie-poster-design.png")
                      }
                      alt="green iguana"
                    />
                    <CardContent className="card-content">
                      <Typography
                        variant="h5"
                        component="div"
                        className="fs-6 card-content-text"
                      >
                        {movieItem.info.Title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default NowShowing;
