import React, { useEffect, useState } from 'react'
import '../Styles/faq.css'
import { clientService } from '../utils/Service'

const Faq = () => {
  const [activeCollapse, setActiveCollapse] = useState(null)
  const [faq, setFaq] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getCardDetails()
  }, [])

  const getCardDetails = async () => {
    try {
      setLoading(true)
      const res = await clientService.get('front/faq')
      setFaq(res?.data?.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleCollapseToggle = (id) => {
    setActiveCollapse((prevId) => (prevId === id ? null : id))
  }

  return (
    <div className="container parent-view">
      <div className="collap-view mt-3">
        <h2 className="text-color head-view">FAQs</h2>
        <div className="horizontal-line my-3"></div>
        {loading ? (
          <div className="faqloder-view">
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          faq.map((item, index) => {
            const collapseId = `collapse-${index}`
            return (
              <div key={index} className="main-view">
                <div className="question-view">
                  <p className="quetions">{item.question}</p>
                  <button
                    className="btn-view"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${collapseId}`}
                    aria-expanded={activeCollapse === index}
                    aria-controls={collapseId}
                    onClick={() => handleCollapseToggle(index)}
                  >
                    {activeCollapse === index ? '-' : '+'}
                  </button>
                </div>
                <div
                  className={`collapse ${
                    activeCollapse === index ? 'show' : ''
                  }`}
                  id={collapseId}
                >
                  <div className="card card-body">{item.answer}</div>
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default Faq
