import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const MovieListsByDay = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <div>MovieListsByDay</div>
}

export default MovieListsByDay
