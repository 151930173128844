/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFoodData } from "../store/slices/foodSlice";
import "../Styles/food.css";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { Skeleton } from "@mui/material";

const Food = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const foodData = useSelector((state) => state.food.foodData);

  const handleCategoryClick = (cate, foodCat) => {
    navigate("/food-details/" + cate.id + "/" + foodCat.id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div className="container">
      <h5 style={{ marginTop: 20, padding: 10 }} className="text-white">
        All food and drinks at FoMo are ordered directly from your allocated
        cinema seat. Here’s the full menu to whet your appetite. To see it in
        PDF form,{" "}
        <a className="pink-color" href="/assets/FoMo_OnePageMenu.pdf">
          click HERE
        </a>
        . Book your ticket now!
      </h5>
      {foodData?.categories ? (
        <>
          <div className="food-main-view">
            <div className="food-container">
              {foodData?.categories?.map((foodCat, foodCatIndex) => (
                <div key={`food-${foodCatIndex}`} className="food-category">
                  <div className="category-header">
                    <div className="text-white fs-5">{foodCat?.name}</div>
                  </div>
                  <div className="category-content">
                    {foodCat?.sub_category?.map((subcat) => (
                      <div
                        onClick={() => handleCategoryClick(foodCat, subcat)}
                        key={subcat.id}
                        className="product-item"
                      >
                        <div key={subcat.id} className="sub-category">
                          <div className="product-view justify-content-start row">
                            <img src={subcat.products[0]?.icon} />
                            <div className="text-white fs-6">{subcat.name}</div>
                          </div>
                        </div>
                        {/* {subcat?.products?.map((product) => (
                            
                              <div>
                                <img
                                  src={product.icon}
                                  alt={product.name}
                                  height={100}
                                  width={100}
                                />
                              </div>
                              <div className="text-white product-name my-2">
                                {product.name}
                              </div>
                            </div>
                          ))} 
                        </div>
                      </div>
                      */}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="food-main-view">
          <div className="food-container">
            {foodData?.categories?.map((foodCat, foodCatIndex) => {
              return (
                <div key={`food-${foodCatIndex}`} className="food-category">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className="text-white my-2">{foodCat?.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Food;

{
  /* <div className="food-main-view">
          <div className="food-container">
            {foodData?.categories?.map((foodCat, foodCatIndex) => {
              {
                console.log(foodCat)
              }
              return (
                <div
                  key={`food-${foodCatIndex}`}
                  className="food-category"
                  // onClick={() => handleCategoryClick(foodCat)}
                >
                  <div className="text-white my-2 fs-4 text-decoration-underline">
                    {foodCat?.name}
                  </div>
                  <div className="gap-3">
                    {foodCat?.sub_category?.map((subcat) => {
                      return (
                        <div key={subcat.id}>
                          <div className="text-white fs-5">{subcat.name}</div>
                          <div className="d-flex gap-3 product-view">
                            {subcat?.products?.map((product) => {
                              return (
                                <div key={product.id}>
                                  <div>
                                    <img
                                      src={product.icon}
                                      height={120}
                                      width={120}
                                    />
                                  </div>
                                  <div
                                    className="text-white"
                                    style={{ width: 100 }}
                                  >
                                    {product.name}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div> */
}
