/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
//Import Library's
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../store/slices/authSlice";
import firebase, { auth } from "./../utils/firebaseApp";

//Import CSS
import "../Styles/login.css";
import "react-phone-input-2/lib/style.css";

const Login = ({ onLoginSuccess }) => {
  const dispatch = useDispatch();

  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [showOtp, setShowOtp] = useState(false);

  const onSignUp = () => {
    try {
      if (mobileNo.trim() === "") {
        alert("Please enter a mobile number");
        return;
      }
      setLoading(true);
      const _mobileNo = mobileNo.length < 10 ? `0${mobileNo}` : mobileNo;
      const formatPhoneNumber = "+" + _mobileNo;
      let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
      auth
        .signInWithPhoneNumber(formatPhoneNumber, verify)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setShowOtp(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
      setLoading(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onOtpVerify = () => {
    try {
      setLoading(true);
      window.confirmationResult
        .confirm(otp)
        .then(async (res) => {
          setUser(res.user);
          setLoading(false);
          if (onLoginSuccess) {
            onLoginSuccess();
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid d-flex flex-column align-items-center mt-5 login-container">
      <div className="login-details">
        <h3 className="login-heading">Login or register a new Fomo account</h3>
        <h5 className="about-account">
          An account is necessary to purchase tickets, food and beverages at
          Fomo
        </h5>
      </div>
      <div>
        {!user ? (
          <>
            {!showOtp ? (
              <>
                <div className="d-flex flex-column">
                  <div className="mb-4 text-white mt-4">Mobile Number</div>
                  <PhoneInput
                    country={"au"}
                    value={mobileNo}
                    onChange={setMobileNo}
                    className="mb-3 custom-input"
                    inputStyle={{ border: "none", borderRadius: 4 }}
                    autoFormat={false}
                  />
                  <div id="recaptcha-container"></div>
                  <button
                    type="button"
                    onClick={onSignUp}
                    id="sign-in-button"
                    className="login-btn mb-3"
                  >
                    {loading ? (
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Send OTP pass to my phone"
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="mt-4">
                  <input
                    className="otp-input"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    maxLength={6}
                  />
                </div>

                <button
                  type="button"
                  onClick={onOtpVerify}
                  className="login-btn mt-3"
                  style={{ width: "100%", display: "block", margin: "0" }}
                >
                  {loading ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              </>
            )}
          </>
        ) : (
          <div className="alert alert-success mt-3">Login Successfully</div>
        )}
      </div>
    </div>
  );
};

export default Login;

{
  /* <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    shouldAutoFocus
                    renderInput={(props) => (
                      <input
                        {...props}
                        type="text"
                        className="otp-input"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                      />
                    )}
                    inputStyle={{
                      height: 45,
                      width: 45,
                      border: 'none',
                    }}
                    // isInputNum={true}
                  /> */
}
