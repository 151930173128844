import React from 'react'
import '../Styles/fail.css'

const BookingFail = () => {
  return (
    <div className="position-absolute top-50 start-50 translate-middle">
      <div>
        <div className="text-white">
          <h5 className="fail">Something Went Wrong</h5>
          <h5 className="fail">Please try again Later</h5>
        </div>
      </div>
    </div>
  )
}

export default BookingFail
