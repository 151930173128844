import React from 'react'
import '../Styles/fail.css'

const PaymentFail = () => {
  return (
    <div className="position-absolute top-50 start-50 translate-middle">
      <div className="text-white">
        <h5 className="fail">Something Went Wrong !</h5>
        <h5 className="fail">You cancle your payment</h5>
        <h5 className="fail">Please fill Payment Card Details</h5>
      </div>
    </div>
  )
}

export default PaymentFail
