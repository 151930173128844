import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


const firebaseConfig = {
  apiKey: 'AIzaSyAiIyZH73Qs8-5i--WhjpH7MECwo2M7Q6E',
  authDomain: 'fomo-de420.firebaseapp.com',
  projectId: 'fomo-de420',
  storageBucket: 'fomo-de420.appspot.com',
  messagingSenderId: '755559139318',
  appId: '1:755559139318:web:3be701ecf81baf0cc229d7',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export const auth = firebase.auth()
export default firebase
