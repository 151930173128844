/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { clientService } from '../../utils/Service'

// Define an initial state for the moive data
const authInitialState = {
  authUser: {},
  isLoggedn: false,
  isCompleteProfile: false,
  showLogin: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setLoggedInUser: (state, action) => {
      state.authUser = action.payload
      state.isLoggedn = action.payload.phoneNumber ? true : false
    },

    setIsLogged: (state, action) => {
      state.isLoggedn = action.payload
    },
    setIsCompleteProfile: (state, action) => {
      state.isCompleteProfile = action.payload
    },
    logoutUser: (state) => {
      state.authUser = {}
      state.isLoggedn = false
      state.isCompleteProfile = false
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload
    },
  },
})

export const loginUsingToken = (data) => async (dispatch) => {
  try {
    const res = await clientService.post('front/checktoken', data)
    dispatch(setLoggedInUser(res.data.data))
    dispatch(setIsLogged(true))
  } catch (error) {
    console.error('Error :', error)
  }
}

export const {
  setLoggedInUser,
  setIsLogged,
  setIsCompleteProfile,
  logoutUser,
  setShowLogin,
} = authSlice.actions

export default authSlice.reducer
