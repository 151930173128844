import React, { useEffect, useState } from 'react'
import { clientService } from '../../utils/Service'
import '../../Styles/paymentCardDetails.css'
import { Button, Modal } from 'react-bootstrap'

const PaymentDetails = () => {
  const [paymentCards, setPaymentCards] = useState([])
  const [loader, setLoader] = useState(false)
  const [deleteId, setDeleteId] = useState(0)
  useEffect(() => {
    getCardDetails()
  }, [])

  const getCardDetails = async () => {
    setLoader(true)
    const res = await clientService.post('front/card/list', {})
    setPaymentCards(res.data.data)
    setLoader(false)
  }

  const deleteCard = async () => {
    setLoader(true)
    await clientService.post('front/card/delete', {
      card_id: deleteId,
    })
    setDeleteId(0)
    getCardDetails()
    setLoader(false)
  }

  const linkNewCard = async () => {
    setLoader(true)
    const res = await clientService.get('front/card/session')
    if (res.data.status) {
      window.location.href = res.data.data.links[1].href
    } else {
      alert('Please try again')
    }
    setLoader(false)
  }

  return (
    <div className="container payment-details-container">
      <div style={{ flex: 1 }}>
        <h4 className="text-white">Payment Details</h4>
        {loader ? (
          <div className="faqloder-view">
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="">
              {paymentCards?.map((item) => {
                return (
                  <div key={`card_id${item.id}`} className="payment-card">
                    <div className="text-white">
                      {'XXXX-XXXX-XXXX-XX' + item.card_number.substring(14)}
                    </div>
                    <img
                      alt=""
                      src={require('../../Assets/Images/delete-red-icon.png')}
                      height={25}
                      width={25}
                      onClick={() => {
                        setDeleteId(item.id)
                      }}
                    />
                  </div>
                )
              })}
            </div>
            <button
              className="btn btn-primary link-card-button"
              onClick={linkNewCard}
            >
              Add New Card
            </button>
          </>
        )}
      </div>
      <Modal show={deleteId !== 0}>
        <Modal.Header>Warning!</Modal.Header>
        <Modal.Body>Are you sure you want to delete the card?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteId(0)
            }}
          >
            No
          </Button>
          <Button variant="danger" onClick={deleteCard}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default PaymentDetails
