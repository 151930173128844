import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../utils/firebaseApp";
import { useDispatch, useSelector } from "react-redux";
import DashBoardPreview from "../pages/DashBoardPreview";
import { loginUsingToken } from "../store/slices/authSlice";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector((state) => state.auth.isLoggedn);
  const isUserDetail = useSelector((state) => state.auth.authUser);
  const [authLoaded, setAuthLoaded] = useState(false);
  useEffect(() => {
    if (!isUserLoggedIn) {
      auth.onAuthStateChanged((authUser) => {
        if (!!authUser) {
          dispatch(
            loginUsingToken({
              token: auth.currentUser.auth.currentUser.accessToken,
            })
          );
        } else {
          setAuthLoaded(true);
        }
      });
    } else {
      setAuthLoaded(true);
    }
  }, []);

  useEffect(() => {
    setAuthLoaded(true);
  }, [isUserLoggedIn]);
  if (!authLoaded) {
    return <DashBoardPreview />;
  }

  if (!isUserLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;
