import React, { useEffect, useState } from 'react'
import '../Styles/buyerDetails.css'

const GiftCardBuyerDetails = () => {
  const [buyerFormData, setBuyerFormdata] = useState({
    name: '',
    email: '',
    mobile_no: '',
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (buyerFormData.name && buyerFormData.email) {
    } else {
      alert('Name and Email is Mandatory field.')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="container-fluid main-section">
      <h4 className="text-center text-white">Personal Details</h4>
      <form onSubmit={handleSubmit} className="mt-3 ">
        <div className="mb-2">
          <label className="text-white" for="name">
            Name *
          </label>
          <input
            id="name"
            type="text"
            // placeholder="Name"
            className="input-view"
            value={buyerFormData.name}
            onChange={(e) =>
              setBuyerFormdata({ ...buyerFormData, name: e.target.value })
            }
          />
        </div>

        <div className="mb-2">
          <label className="text-white" for="email">
            email *
          </label>
          <input
            id="email"
            type="email"
            // placeholder="Email"
            className="input-view"
            value={buyerFormData.email}
            onChange={(e) =>
              setBuyerFormdata({ ...buyerFormData, email: e.target.value })
            }
          />
        </div>
        <div className="mb-2">
          <label className="text-white" for="Phone">
            Phone
          </label>
          <input
            type=""
            // placeholder="Phone"
            className="input-view"
            value={buyerFormData.mobile_no}
            onChange={(e) =>
              setBuyerFormdata({ ...buyerFormData, mobile_no: e.target.value })
            }
          />
        </div>
        <div className="my-4">
          <button type="submit" className="buyer-submit-btn">
            Continue to Payment
          </button>
        </div>
      </form>
    </div>
  )
}

export default GiftCardBuyerDetails
