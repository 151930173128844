/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { clientService } from '../../utils/Service'
import moment from 'moment'
import '../../Styles/food.css'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { Skeleton } from '@mui/material'

const FoodOrders = () => {
  const navigate = useNavigate()
  const [foodOrder, setFoodOrder] = useState([])
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 12

  const indexOfLastItem = (currentPage + 1) * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = foodOrder.slice(indexOfFirstItem, indexOfLastItem)

  const getFoodOrdersDetail = async () => {
    setLoader(true)
    try {
      const response = await clientService.get(`front/order-list`)
      setFoodOrder(response?.data?.data)
    } catch (error) {
      console.error('Error fetching food orders:', error)
    } finally {
      setLoader(false)
    }
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate])

  useEffect(() => {
    getFoodOrdersDetail()
  }, [])

  const handleNavigation = (orderItem, created_at, id, total_amount) => {
    navigate('/food-order-details', {
      state: { orderItem, created_at, id, total_amount },
    })
  }

  return (
    <div className="container p-3">
      <div className="">
        {foodOrder.length <= 0 ? (
          <div className="no-record-view">
            <h4 className="text-white text-center">No record Found</h4>
          </div>
        ) : (
          <>
            <div>
              <h4 className="text-white text-center my-3">Food Orders</h4>
            </div>
            {loader ? (
              <div className="d-flex flex-wrap justify-content-center">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                  <div
                    key={index}
                    className="d-flex order-items-view"
                    style={{}}
                  >
                    <div
                      className="food-order-list-view"
                      style={{ width: '100%' }}
                    >
                      <Skeleton
                        variant="rounded"
                        width={210}
                        height={60}
                        style={{ marginBottom: 6 }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="d-flex flex-wrap justify-content-center">
                {currentItems?.map((item, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="d-flex order-items-view"
                        style={{}}
                      >
                        <div
                          className="food-order-list-view"
                          key={item.id}
                          onClick={() =>
                            handleNavigation(
                              item?.order_items,
                              item?.created_at,
                              item.id,
                              item?.total_amount,
                            )
                          }
                        >
                          <div className="w-100">
                            <div className="text-white product-name">
                              {/* {orderItem?.product_name} */}
                            </div>
                            <div className="text-white">
                              {moment(item?.created_at).format(
                                'ddd, DD MMM | LT',
                              )}{' '}
                            </div>
                            <div className="text-white">
                              Order Id : {item?.id}
                            </div>
                            <div className="text-white">
                              Amount Paid : ${item?.total_amount}
                            </div>
                          </div>
                          <div className="next-img-view">
                            <img
                              alt="next"
                              src={require('../../Assets/Images/next.png')}
                              height={25}
                              width={25}
                              className="white-image"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            )}
          </>
        )}

        {foodOrder?.length > itemsPerPage && (
          <div className="d-flex justify-content-center">
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              pageCount={Math.ceil(foodOrder.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active-page'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FoodOrders
