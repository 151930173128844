import { createSlice } from "@reduxjs/toolkit";
import { clientService } from "../../utils/Service";

// Define an initial state for the food data
const foodInitialState = {
  foodData: [],
  isLoading: true,
};

const foodSlice = createSlice({
  name: "food",
  initialState: foodInitialState,
  reducers: {
    setFoodData: (state, action) => {
      state.foodData = action.payload;
      state.isLoading = false;
    },
  },
});

// Create an async action to fetch food data
export const getFoodData = () => async (dispatch) => {
  try {
    const res = await clientService.get("front/categories");
    dispatch(foodSlice.actions.setFoodData(res.data.data));
  } catch (error) {
    console.error("Error fetching Food data:", error);
  }
};

export const { setFoodData } = foodSlice.actions;

export default foodSlice.reducer;
