import React, { useEffect, useState } from 'react'
import '../Styles/faq.css'
import { useNavigate } from 'react-router-dom'
import '../Styles/giftCard.css'

const GiftCard = () => {
  const navigate = useNavigate()
  const [activeCollapse, setActiveCollapse] = useState(null)
  const [cardValue, setCardValue] = useState('')

  const giftData = [
    {
      id: 1,
      question:
        'Why do I have to download the FoMo Cinemas app to order food inside the cinema?',
      answer:
        'FoMo Cinemas has developed our App to reduce interruption to your movie by service staff. By pairing your personal App with the in-seat ordering screen you can order food and drinks throughout the movie, with charges to your nominated credit card processed in real-time. By using the FoMo App you won’t need to wait for your order to be taken by a staff member or for the final minutes of the film to be interrupted by the delivery of the bill – all ordering and billing is done through the App to ensure the least amount of disruption to your movie experience.',
    },
    {
      id: 2,
      question: 'How often can I order food throughout the film? ',
      answer:
        'You can order as many times as you wish but order taking will conclude approximately 20 minutes prior to the conclusion of the feature.  FoMo Cinemas monitors the responsible service of alcohol.',
    },
    {
      id: 3,
      question: 'Why can’t I swap seats after I have ordered food?',
      answer:
        'As your food and drink order has been paired to your allocated seat, moving seats after you order will result in your order being delivered to your original seat. As our staff have been trained to be as discreet as possible, food will be delivered to the specified seat only.',
    },
  ]

  const dropDownData = [
    {
      id: 1,
      price: '$ 30',
    },
    {
      id: 2,
      price: '$ 50',
    },
    {
      id: 3,
      price: '$ 100',
    },
    {
      id: 4,
      price: '$ 150',
    },
  ]

  const handleCollapseToggle = (id) => {
    setActiveCollapse((prevId) => (prevId === id ? null : id))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleCardPurchase = (e) => {
    setCardValue(e)
  }

  const handlePurchaseButton = () => {
    navigate('/personal-details')
  }

  return (
    <div className="container d-flex justify-content-center align-items-center">
      <div className="mt-4">
        <div
          className="card mb-3"
          style={{
            maxWidth: '100%',
            padding: 15,
            boxShadow: '0 0 8px white',
            borderRadius: 5,
            cursor: 'pointer',
          }}
        >
          <div className="row g-0">
            <div className="col-md-7">
              <img
                src={require('../Assets/Images/giftVoucher.png')}
                className="img-fluid"
                alt="giftcard"
              />
            </div>
            <div className="col-md-5">
              <div className="card-body">
                <h5 className="card-title">title</h5>
                <p className="card-text">
                  dummy content This is a wider card with supporting text below
                  as a natural lead-in to additional content. This content is a
                  little bit longer.content This is a wider card with supporting
                  text below as a natural lead-in to additional content. This
                  content is a little bit longer.content This is a wider card
                  with supporting text below as a natural lead-in to additional
                  content. This content is a little bit longer.content This is a
                  wider card with supporting text below as a natural lead-in to
                  additional content. This content is a little bit longer
                </p>
                <select
                  id="cardPrice"
                  name="cardPrice"
                  onChange={(e) => handleCardPurchase(e.target.value)}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="">Select Card Price</option>
                  {dropDownData?.map((cardPrice) => (
                    <option
                      key={cardPrice.id}
                      value={cardPrice.price}
                      className="cardPrice"
                    >
                      {cardPrice.price}
                    </option>
                  ))}
                </select>

                <button className="buy-now-btn" onClick={handlePurchaseButton}>
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
          {giftData.map((item, index) => {
            const collapseId = `collapse-${item.id}`
            return (
              <div key={index} className="main-view">
                <div className="question-view">
                  <p className="quetions">{item.question}</p>
                  <button
                    className="btn-view"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${collapseId}`}
                    aria-expanded={activeCollapse === item.id}
                    aria-controls={collapseId}
                    onClick={() => handleCollapseToggle(item.id)}
                  >
                    {activeCollapse === item.id ? (
                      <img
                        alt=""
                        src={require('../Assets/Images/white-up-arrow.png')}
                        height={15}
                        width={15}
                      />
                    ) : (
                      <img
                        alt=""
                        src={require('../Assets/Images/white-down-arrow.png')}
                        height={15}
                        width={15}
                      />
                    )}
                  </button>
                </div>
                <div
                  className={`collapse ${
                    activeCollapse === item.id ? 'show' : ''
                  }`}
                  id={collapseId}
                >
                  <div className="card card-body">{item.answer}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default GiftCard
