import React, { useEffect, useState } from 'react'
import '../Styles/aboutUs.css'
import { clientService } from '../utils/Service'

const About = () => {
  const [content, setContent] = useState('')
  const [loader, setLoder] = useState(false)
  useEffect(() => {
    getPrivacyPage()
  }, [])

  const getPrivacyPage = async () => {
    setLoder(true)
    const res = await clientService.post('front/content', {
      title: 'About FOMO',
    })
    setContent(res.data.data.content)
    setLoder(false)
  }
  return (
    <div className="container text-white">
      {loader ? (
        <>
          {' '}
          <div className="aboutusloder-view">
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      )}
    </div>
  )
}

export default About
