/* eslint-disable no-useless-concat */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
//Import library's
import {
  CardActionArea,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//Import CSS
import "../Styles/common.css";
import "../Styles/home.css";

const CoomingSoon = () => {
  const navigate = useNavigate();
  const _coomingSoonMovieData = useSelector(
    (state) => state.movie.coomingSoonMovieData
  );

  const _coomingSoonMovies = useMemo(() => {
    const sortedMovies = [..._coomingSoonMovieData];
    sortedMovies.sort(
      (a, b) => new Date(a.releasing_date) - new Date(b.releasing_date)
    );

    return sortedMovies;
  }, [_coomingSoonMovieData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const handleNavigation = (index) => {
    navigate("/movie-details/" + `${index}`);
  };

  const createUrl = (title) => {
    return encodeURIComponent(title);
  };

  return (
    <div className="dashboard">
      <Container className="mb-4">
        <Grid container>
          <Grid item className="mb-2"></Grid>
        </Grid>
        <Grid container spacing={2}>
          {_coomingSoonMovies?.map((movieItem, index) => {
            return (
              <Grid item xs={6} md={4} lg={3} sm={6} key={`movie_${index}`}>
                <Card
                  onClick={() =>
                    handleNavigation(
                      `${movieItem.film_id}/${createUrl(movieItem.info.Title)}`
                    )
                  }
                >
                  <CardActionArea>
                    <CardMedia
                      className="responsive-img"
                      component="img"
                      height="410"
                      image={
                        movieItem.info.GraphicUrl
                          ? movieItem.info.GraphicUrl
                          : require("../Assets/Images/movie-poster-design.png")
                      }
                      alt="green iguana"
                    />
                    <CardContent className="card-content">
                      <Typography
                        variant="h5"
                        component="div"
                        className="fs-6 card-content-text"
                      >
                        {movieItem.info.Title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default CoomingSoon;

// const coomingSoonMovies = useSelector((state) => {
//   const sortedMovies = [...state.movie.coomingSoonMovieData];
//   sortedMovies.sort(
//     (a, b) => new Date(a.releasing_date) - new Date(b.releasing_date)
//   );

//   return sortedMovies;
// });
