/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import '../Styles/timeAndseats.css'
import 'react-calendar/dist/Calendar.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
const calendarImage = require('../Assets/Images/calendar.png')

const TimeAndSeats = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const movie = location

  const moiveTimesAndSeats = movie?.state?.movieDetails

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectTime, setSelectTime] = useState(false)
  const [selectSeat, setSelectSeat] = useState(false)
  const [selectedTime, setSelectedTime] = useState('')
  const [selectedSeats, setSelectedSeats] = useState([])

  const timeList = [
    {
      id: 1,
      time: '03:00pm',
    },
    {
      id: 2,
      time: '05:30pm',
    },
    {
      id: 3,
      time: '06:15pm',
    },
    {
      id: 4,
      time: '08:15pm',
    },
    {
      id: 5,
      time: '11:15pm',
    },
    {
      id: 6,
      time: '12:25am',
    },
  ]

  const seatsList = [
    {
      id: 1,
      seatN0: 1,
    },
    {
      id: 2,
      seatN0: 2,
    },
    {
      id: 3,
      seatN0: 3,
    },
    {
      id: 4,
      seatN0: 4,
    },
    {
      id: 5,
      seatN0: 5,
    },
    {
      id: 6,
      seatN0: 6,
    },
    {
      id: 7,
      seatN0: 7,
    },
    {
      id: 8,
      seatN0: 8,
    },
    {
      id: 9,
      seatN0: 9,
    },
    {
      id: 10,
      seatN0: 10,
    },
  ]

  const handleTimeSelecting = (timeId) => {
    setSelectedTime(timeId)
    setSelectTime(true)
  }

  const handleSeatSelecting = (seatId) => {
    setSelectedSeats([seatId])
  }

  const handlebutton = () => {
    if (selectedTime && selectedSeats.length > 0) {
      navigate(
        `/booking-details?time=${selectedTime}&seats=${selectedSeats.join(
          ',',
        )}`,
      )
    } else {
      alert('Please select both time and seats before proceeding.')
    }
  }

  return (
    <div className="container timeandSetsContainer">
      {/* <div>
        <h2 className="fs-1 text-white">Gravity</h2>
        <div className="d-flex justify-content-between align-item-center mt-4">
          <p className="fs-4 text-white">July 24, Thursday</p>
          <a onClick={''}>
            <img src={calendarImage} height={30} width={30} />
          </a>
        </div>

        <div className="mt-3">
          <p className="fs-4 fw-bold text-white">Select Timing</p>
          <div className="d-flex flex-wrap gap-2 time-view">
            {moiveTimesAndSeats.showTimes.map((item, index) => {
              return (
                <a
                  key={index}
                  onClick={() => handleTimeSelecting(item.time)}
                  className={
                    selectTime === true ? 'selected-view' : 'not-selected-view'
                  }
                >
                  {moment(item.time).format('HH:mm')}
                </a>
              )
            })}
          </div>
        </div>

        <div className="mt-5">
          <p className="fs-4 fw-bold text-white">How many seats ?</p>
          <div className="d-flex flex-wrap gap-2 seat-view">
            {seatsList.map((item, index) => {
              return (
                <a
                  key={index}
                  onClick={() => handleSeatSelecting(item.id)}
                  className={`${
                    selectedSeats.includes(item.id)
                      ? 'selected-seat-view selected'
                      : 'not-selected-seat-view'
                  }`}
                >
                  {item.seatN0}
                </a>
              )
            })}
          </div>
        </div>

        <button className="btn" onClick={handlebutton}>
          Select Seat
        </button>
      </div> */}
    </div>
  )
}

export default TimeAndSeats
